<template>
<!-- 系统版本 -->
  <div>
    <el-breadcrumb separator="">
      <el-breadcrumb-item class="sptor">版本管理</el-breadcrumb-item>
      <el-breadcrumb-item class="sptor">系统版本</el-breadcrumb-item>
      <el-breadcrumb-item v-if="activeName == 1" class="sptor isactive">总系统</el-breadcrumb-item>
      <el-breadcrumb-item v-if="activeName == 2" class="sptor isactive">子系统</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="setting_main_bg">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="总系统" name="1">
          <VerTot v-if="activeName == 1"></VerTot>
        </el-tab-pane>
        <el-tab-pane label="子系统" name="2">
          <VerSub v-if="activeName == 2"></VerSub>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import VerSub from '@/components/verSub.vue'
import VerTot from '@/components/verTot.vue'

export default {
  components: {
    VerSub,
    VerTot
  },
  data () {
    return {
      activeName: '1',
    }
  },
  created () {
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
    },
  }
}
</script>

<style scoped>
.isactive >>> .el-breadcrumb__inner {
  color: #196DF7;
}
</style>
