<template>
<!-- 系统版本子系统 -->
  <div>
    <div class="unit_cont_box">
      <div class="iconfont iconjia settingAdd" @click="handleUserAdd()"><span>新增</span></div>
        <div class="flexAC ut_top">
          <el-input v-model="vname"  placeholder="请输入版本号/更新内容" clearable class="contInput"></el-input>
          <el-select v-model="vtype" placeholder="版本类型" clearable class="utG setSe">
            <el-option
              v-for="(item,index) in vtypeList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button class="settingSearch" @click="handleSearch()">搜索</el-button>
          <el-button class="settingReset" @click="handleReset()">重置</el-button>
        </div>
      </div>
      <div class="user_table">
      <el-table
        stripe
        :data="dataList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="版本号"
          prop="vnum">
        </el-table-column>
        <el-table-column
          label="版本类型"
          prop="vtypename">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.vtype == 1">PC</span>
            <span v-if="scope.row.vtype == 2">Android</span>
            <span v-if="scope.row.vtype == 3">iOS</span>
          </template> -->
        </el-table-column>
        <el-table-column
          label="更新人员"
          prop="username">
        </el-table-column>
        <el-table-column
          label="更新内容"
          show-overflow-tooltip
          prop="contents">
        </el-table-column>
        <el-table-column
          label="更新时间"
          prop="creatime">
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[size]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!-- 新增 -->
    <el-dialog title="版本新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="fromRule" ref="addFrom">
        <el-form-item label="版本号"  prop="vnum">
          <el-input v-model="addfrom.vnum"  placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="类型"  prop="vtype">
          <div class="typeBox">
            <el-radio v-model="addfrom.vtype" label="1">pc</el-radio>
            <el-radio v-model="addfrom.vtype" label="2">Andriod</el-radio>
            <el-radio v-model="addfrom.vtype" label="3">ios</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="更新内容"  prop="contents">
          <el-input type="textarea" v-model="addfrom.contents"  placeholder="请输入更新内容"></el-input>
        </el-form-item>
        <el-form-item v-if="addfrom.vtype === '2'" label="上传apk" class="foItem" prop="apkpath">
          <el-upload
              ref="upload"
              class="upload-demo"
              accept=".apk"
              :action="action"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              :on-success="handleSuccess">
            <div class="upbtn"><i class="el-icon-upload2"></i>上传apk</div>
            <span class="upSpan">只支持上传apk文件,且不超过100M</span>
            <div slot="tip" class="flexCB">
              <div v-if="addFileName !== ''"><span class="iconfont iconfujian upIco1"></span>{{addFileName}}</div>
              <label v-if="addFileName !== ''" class="el-icon-circle-close upIco upIco" @click="upDelete"></label>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="addFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVersionInfo, addVersionInfo } from '@/api/version.js'

export default {
  data () {
    return {
      vname: '',
      page: 1,
      size: 10,
      total: 0,
      dataList: [],
      vtype: '',
      vtypeList: [
        { label: 'PC', value: 1 },
        { label: 'Android', value: 2 },
        { label: 'iOS', value: 3 }
      ],
      addFromShow: false,
      addfrom: {
        vnum: '',
        vtype: '2',
        contents: '',
        apkpath: '',
        userid: ''
      },
      fromRule: {
        vnum: [{ required: true, trigger: 'blur', message: '版本号不能为空！' }],
        vtype: [{ required: true, trigger: 'blur', message: '请选择类型！' }],
        contents: [{ required: true, trigger: 'blur', message: '更新内容不能为空！' }],
        apkpath: [{ required: true, trigger: 'blur', message: '请上传apk！' }]
      },
      action: this.BASE_URL + '/v1/sysconfig/file_save',
      addFileName: '',
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        Loginsource: '1',
        Anotherplace: 'fc9b5d6e06d011ec8b90244bfe7a0fff'
      }
    }
  },
  created () {
    this.userid = JSON.parse(window.localStorage.getItem('loginInfo')).userid
    this.init()
  },
  methods: {
    init () {
      const params = {
        vname: this.vname,
        page_index: this.page,
        page_size: this.size,
        vtype: this.vtype,
        vstate: '2'
      }
      getVersionInfo(params).then(res => {
        if (res.status === '1') {
          this.dataList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.dataList = []
          this.total = 0
        }
      })
    },
    beforeAvatarUpload (file) {
      var type = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJPG = 'apk'.includes(type)
      const isLt100M = file.size / 1024 / 1024 < 100
      if (!isJPG) {
        this.$message.error('上传文件类型为apk!')
        return false
      } else if (!isLt100M) {
        this.$message.error('上传附件大小不能超过 100MB!')
        return false
      } else {
        return isJPG && isLt100M
      }
    },
    handleSuccess (res, file) {
      if (res.status === '1') {
        // this.addFileName = file.name
        this.addfrom.apkpath = res.data.filepath
      }
    },
    upDelete () {
      this.addFileName = ''
      this.addfrom.apkpath = ''
    },
    handleUserAdd () {
      this.addFromShow = true
      this.upDelete()
    },
    addFromCancel () {
      this.addFromShow = false
      this.$refs['upload'].clearFiles()
      this.$refs.addFrom.resetFields()
    },
    // 添加提交
    addFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        if (this.addfrom.vtype !== '2') {
          this.upDelete()
        }
        const params = {
          vnum: this.addfrom.vnum,
          vtype: this.addfrom.vtype,
          contents: this.addfrom.contents,
          apkpath: this.addfrom.apkpath,
          userid: this.userid
        }
        addVersionInfo(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.vname = ''
      this.vtype = ''
      this.init()
    },
  }
}
</script>

<style scoped>
.typeBox,.upload-demo{
  width: 100%;
  margin-top: 24px;
}
.upbtn{
  width: 106px;
  height: 35px;
  background: #196DF7;
  border: 1px solid #196DF7;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
}
.upSpan{
  margin-left: 8px;
  color: #A5B7CC;
  font-size: 12px;
}
.upIco1{
  margin-right: 8px;
}
.upIco{
  font-size: 16px;
  color: #FF4D4F;
  cursor: pointer;
}
</style>
